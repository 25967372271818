<template>
  <div class="home">
    <img class="img__body" :src="imgBody" alt="" />
    <div class="container__logo">
      <img class="logo__clear" :src="imgLogo" alt="" />
    </div>

    <div class="container__flex">
      <h1 class="title__home">404</h1>
      <h1 class="title__home">{{ notFoundText }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      imgBody: require("@/assets/img/clear people sin letras.png"),
      imgLogo: require("@/assets/img/clear people naranja (1).png"),
    };
  },
  computed: {
    notFoundText() {
      return this.text === "" ? "Pagina no encontrada" : this.text;
    },
  },
};
</script>
